<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{$route.name}}</h5>
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="4">
              <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama user" v-model="filter.name" id="filter-name" />
            </b-col>
            <!-- <b-col cols="4">
              <form-group-input ref="filter-role" type="text" placeholder="" :need-label="true" label="role user" v-model="filter.role" id="filter-role" />
            </b-col> -->
            <b-col cols="4">
              <form-group-input ref="filter-email" type="text" placeholder="" :need-label="true" label="email user" v-model="filter.email" id="filter-email" />
            </b-col>
            <b-col cols="4">
              <form-group-input ref="filter-phone_number" type="text" placeholder="" :need-label="true" label="nomor hp user" v-model="filter.phone_number" id="filter-phone_number" />
            </b-col>
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br>
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn type="button" @click.prevent="clearForm" variant="warning" class="ml-3 text-white">Clear</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" aksi-dot :delete-reason="false" :can-delete="true" :can-edit="false" :data-url="apiUrl.user" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
      <template slot="content_role" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.role !== null ? defaultSlotScope.colData.role : ''}}</span>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'

const userModel = new User()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      isLoading: false,
      apiUrl: {
        user: userModel.endpoint
      },
      columns: [
        {
          prop: 'name',
          label: 'Nama',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          prop: 'email',
          label: 'Email',
          resizable: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: 'phone_number',
          label: 'Nomor Handphone',
          resizable: true,
          sortable: true,
          minWidth: 100
        },
      ],
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
        email: typeof this.$route.query.email !== '-' ? this.$route.query.email : null,
        phone_number: typeof this.$route.query.phone_number !== 'undefined' ? this.$route.query.phone_number : null,
        role: 'customer',
      },
      dataParams: ['name', 'role', 'email', 'phone_number']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  mounted () {
  },
  methods: {
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          name: null,
          role: null,
          email: null,
          phone_number: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    }
  }
}
</script>